<template>
  <card>
    <div slot="header">
      <h4 class="my-2 font-weight-700">MACROS DETAIL</h4>
    </div>
    <div class="chart mb-3 d-flex">
      <div class="doughnut-labels-wrapper" v-if="checkData">
        <p class="doughnut-labels font-weight-600" id="Carbs-circle">Carbs
          <span class="font-weight-normal font-size-12px">{{ getCarbsPercentage }}%</span>
        </p>
        <p class="doughnut-labels font-weight-600" id="Protein-circle">Protein
          <span class="font-weight-normal font-size-12px">{{ getProteinPercentage }}%</span>
        </p>
        <p class="doughnut-labels font-weight-600" id="Fat-circle">Fat
          <span class="font-weight-normal font-size-12px">{{ getFatPercentage }}%</span>
        </p>
      </div>
      <div v-if="checkData" class="macro-pie-chart-width">
        <pie-chart
                 ref="pieChart"
                 :height="350"
                 :chart-data="macroNutrientPieChart.chartData"
                 :extra-options="macroNutrientPieChart.extraOptions"
      />
      </div>
      <div v-else class="d-flex align-items-center h-100 w-100">
        <span class="empty-text">No Data</span>
      </div>
    </div>
  </card>
</template>

<script>
import PieChart from "@/components/Charts/PieChart";

export default {
  name: "MacroNutrientsPieChart",
  props: ['nutrientData'],
  components: {
    PieChart
  },
  data() {
    return {
      macroNutrientPieChart: {
        chartData: {
          labels: ["Carbs", "Protein", "Fat"],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#fe8c19",
                "#ecaf52",
                "#ffcd16",
              ],
              borderWidth: [2]
            }
          ]
        },
        extraOptions: {
          responsive: true,
          tooltips: {
            enabled: true,
            callbacks: {
              label: function (tooltipItem, data) {
                const total = data.datasets[tooltipItem.datasetIndex].data.reduce((prev, next) => prev + next, 0)
                return data.labels[tooltipItem.index]+ ': '+ Math.round(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] / total * 100) + '%'
              }
            }
          }
        }
      },
    }
  },
  watch: {
    nutrientData: function (newVal, oldVal) {
      this.macroNutrientPieChart.chartData.datasets[0].data = newVal
      if (this.checkData)
        this.$refs.pieChart.renderChart(this.macroNutrientPieChart.chartData, this.macroNutrientPieChart.extraOptions)
    },
  },
  computed: {
    checkData() {
      return this.macroNutrientPieChart.chartData.datasets[0].data.every(e => e > 0)
    },
    getCarbsPercentage() {
      return Math.round((this.macroNutrientPieChart.chartData.datasets[0].data[0] /
        this.macroNutrientPieChart.chartData.datasets[0].data.reduce((a, b) => a + b, 0)) * 100)
    },
    getProteinPercentage() {
      return Math.round((this.macroNutrientPieChart.chartData.datasets[0].data[1] /
        this.macroNutrientPieChart.chartData.datasets[0].data.reduce((a, b) => a + b, 0)) * 100)
    },
    getFatPercentage() {
      return Math.round((this.macroNutrientPieChart.chartData.datasets[0].data[2] /
        this.macroNutrientPieChart.chartData.datasets[0].data.reduce((a, b) => a + b, 0)) * 100)
    },
  }
}
</script>

<style scoped>

.doughnut-labels-wrapper {
  margin: 90px 20px;
  padding: 30px;
}

.doughnut-labels {
  font-size: 17px;
  margin: 0;
  padding: 0 0 3px 0;
  position: relative;
}

.doughnut-labels::before {
  content: '';
  position: absolute;
  left: -15px;
  height: 12px;
  width: 12px;
  top: 45%;
  transform: translate(-50%, -50%);
  border-radius: 100px;
}

#Carbs-circle::before {
  border: 3px solid #fe8c19;
}

#Protein-circle::before {
  border: 3px solid #ecaf52;
}

#Fat-circle::before {
  border: 3px solid #ffcd16;
}

.empty-text {
  color: #909399;
  line-height: 60px;
  width: 100%;
  text-align: center;
  font-size: 20px;
}

</style>
