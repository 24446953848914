<template>
  <modal @close="hideModal" :show.sync="modalVisibility" class="pdf-download-modal">
    <div slot="header">&nbsp;</div>
    <div slot="default" class="text-center" v-loading="pdfReportDownloadModalLoader">
      <h5 class="modal-title mb-3">PDF REPORT</h5>
      <p class="mb-4">
        Please choose a report type.
      </p>
      <div>
        <base-button class="btn-quick-report mr-2" @click="getQuickReport">Quick Report</base-button>
        <base-button class="btn-full-report ml-2" @click="getFullReport">Full Report</base-button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "PdfReportDownloadModal",
  props: ['showModal', 'loader'],
  data() {
    return {
      modalVisibility: false,
      pdfReportDownloadModalLoader: false,
    }
  },
  watch: {
    showModal(newVal) {
      this.modalVisibility = newVal
    },
    loader(newVal) {
      this.pdfReportDownloadModalLoader = newVal
    }
  },
  methods: {
    getQuickReport() {
      this.$emit('getReport', 'quick')
    },
    getFullReport() {
      this.$emit('getReport', 'full')
    },
    hideModal() {
      this.$emit('closeNotificationModal', false)
    },
  }
}
</script>

<style scoped>
.btn-quick-report {
  background-color: #832C44 !important;
  border: 1px solid #832C44;
}

.btn-full-report {
  background-color: #618CA4 !important;
  border: 1px solid #618CA4;
}

.pdf-download-modal >>> .modal-body {
  padding-top: 0;
}

.pdf-download-modal >>> .modal-header {
  padding-top: 15px;
  padding-bottom: 0;
}
</style>
