<template>
  <div class="col-xl-6">
    <card id="activity-card" v-loading="loader">
      <template v-slot:header>
        <div class="row align-items-center">
          <div class="col-8">
            <!-- Title -->
            <h4 class="h4 mb-0 font-weight-800 text-uppercase">Activities</h4>
            <!-- Subtitle -->
            <h5 v-if="request.month" class="surtitle mt-1 font-weight-400 text-capitalize ls-0">
              {{ getActivitiesDate }}</h5>
          </div>
          <div class="col-4 text-right">
            <div class="position-relative">
              <a href="#!" class="btn btn-sm btn-neutral" @click="selectActivityMonth">
                <i class="ni ni-calendar-grid-58"></i> {{ getActivityMonth }}
              </a>
              <el-date-picker
                class="activityPicker"
                ref="activityPicker"
                v-model="date"
                type="month"
                @change="changeActivityMonth"
                placeholder="Pick a month">
              </el-date-picker>
            </div>
          </div>
        </div>
      </template>
      <div class="chart">
        <div class="activity-container">
          <div class="activity-name">
            <h4>
              Daily Steps
            </h4>
          </div>
          <div
            class="activity-values-container d-flex flex-row justify-content-between align-items-center position-relative">
            <div class="activity-benefit-heading">
              Calories
            </div>
            <div class="activity-benefit-value">
              {{ Math.round(activities.activity_steps) }}
            </div>
          </div>
        </div>
        <div v-for="activity in activities.activities" :key="activity.id"
             class="activity-container">
          <div class="activity-name">
            <h4>
              {{ activity.name }}
            </h4>
          </div>
          <div
            class="activity-values-container d-flex flex-row justify-content-between align-items-center position-relative">
            <div class="activity-benefit-heading">
              Calories
            </div>
            <div class="activity-benefit-value">
              {{ Math.round(activity.benefit) }}
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>

</template>

<script>
import moment from "moment";
import authHelper from "@/util/authHelper";

export default {
  name: "ActivityCard",
  props: ['patient_id', 'month', 'year', 'calendarDate'],
  data() {
    return {
      loader: false,
      request: {
        patient_id: this.patient_id,
        month: this.month,
        year: this.year,
      },
      date: null,
      activities: {
        activities: [],
        activity_steps: 0,
      },
    }
  },
  watch: {
    calendarDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.request.month = this.month;
        this.request.year = this.year;
        this.date=`${this.request.month}-${this.request.year}`
        this.getActivities();
      }
    },
    month(newVal, oldVal) {
      if (newVal!== oldVal && newVal == null) {
        this.request.month = newVal
        this.getActivities()
      }
    }
  },
  computed: {
    getActivitiesDate() {
      return moment(`${this.date}`).format('MMMM, YYYY');
    },
    getActivityMonth() {
      return this.request.month;
    }
  },
  methods: {
    selectActivityMonth() {
      this.$refs.activityPicker.$el.getElementsByTagName('input')[0].focus();
    },
    changeActivityMonth() {
      this.request.month = moment(this.date).format('MMMM');
      this.request.year = moment(this.date).format('YYYY');
      this.getActivities();
    },
    getActivities() {
      let vm = this;
      vm.loader = true;
      axios.get(this.$store.getters.getBaseUrl + `/api/nutrition/activities?patient_id=${this.patient_id}&month=${this.request.month}&year=${this.request.year}`).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.activities = response.data.data;
      }).catch(error => {
        if (error.response.status != 401) {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Activities',
            message: message
          });
        }
      }).finally(() => {
        vm.loader = false;
      });
    },

  },
  mounted() {
    this.getActivities();
  }
}
</script>

<style scoped>
.activity-container {
  overflow-y: hidden !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 30px 10px 22px;
  border-bottom: 1px solid #f1f1f1;
  text-transform: capitalize;
}

.activity-container:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
}

.activityPicker {
  position: absolute;
  left: -100px !important;
}

.activity-values-container {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.activity-values-container::after {
  content: '';
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  height: 4px;
  width: 4px;
  top: 50%;
  left: 65%;
  background-color: #557A94;
}

.activity-benefit-heading {
  font-size: 13px;
}

.activity-benefit-value {
  margin-left: 10px;
  font-size: 11px;
}
</style>
