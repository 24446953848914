<template>
  <div class="col-md-6">
    <card v-loading="loader" type="default" header-classes="bg-transparent" class="equal-height-columns">
      <h3 class="font-weight-900 pb-2">SURGERY</h3>

      <div class="row pb-3">
        <div class="col-sm-6">
          <span class="history-heading"> Surgery Date </span>
        </div>
        <div class="col-sm-6">
          {{ getSurgeryDate }}
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-sm-6">
          <span class="history-heading"> Days Since Surgery </span>
        </div>
        <div class="col-sm-6">
          {{ getDaysSinceSurgery }}
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-sm-6">
          <span class="history-heading"> Weight Loss/Gain Since Surgery </span>
        </div>
        <div class="col-sm-6">
          {{ geWeightChangeBeforeSurgery() }}
          <span v-if="weights.length > 0">
            <i :class="getWeightChangeBeforeSurgeryArrow"></i>
          </span>
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-sm-6">
          <span class="history-heading"> Total Weight Loss/Gain </span>
        </div>
        <div class="col-sm-6">
          {{ getTotalWeightChange }}
          <span v-if="weights.length > 0">
            <i :class="getTotalWeightChangeArrow"></i>
          </span>
        </div>
      </div>

      <h3 class="font-weight-900 pb-1">HEALTH TRACKERS CONNECTED</h3>

      <ul class="trackers-connected-list">

        <li v-for="(healthTracker, index) in healthTrackers"
            class="tracker-connected" :key="index">
          <img height="50"
               :alt="`${healthTracker} placeholder`"
               :src="`../img/icons/services/${healthTracker}.png`">
        </li>

      </ul>

    </card>
  </div>

</template>

<script>
import moment from "moment";

export default {
  name: "SurgeryCard",
  props: ['loader', 'weights', 'patient', 'healthTrackers'],
  data() {
    return {
      surgeryArrow: '',
    }
  },
  computed: {
    getSurgeryDate() {
      if (this.patient.surgery_date === null) {
        return 'N/A';
      } else {
        return this.$options.filters.globalDateFormat(this.patient.surgery_date);
      }
    },

    getDaysSinceSurgery() {
      if (this.patient.surgery_date === null) {
        return 'N/A';
      }
      let today = moment();
      return today.diff(this.patient.surgery_date, 'days');
    },

    getWeightChangeBeforeSurgeryArrow() {
      if (this.surgeryArrow === 'success-arrow') {
        return 'fas fa-arrow-down text-success';
      } else if (this.surgeryArrow === 'danger-arrow') {
        return 'fas fa-arrow-up text-danger';
      }
      return 'no-arrow';
    },

    getTotalWeightChange() {
      if (this.weights.length === 0 || this.weights.length === 1) {
        return 0;
      }
      let totalWeightChange = this.weights[this.weights.length - 1].weight - this.weights[0].weight;
      return totalWeightChange % 1 !== 0 ? Math.abs(+totalWeightChange.toFixed(2)) : Math.abs(+totalWeightChange);
    },

    getTotalWeightChangeArrow() {
      if (this.getTotalWeightChange === 0) {
        return 'no-arrow';
      }
      if (+this.weights[0].weight < +this.weights[this.weights.length - 1].weight) {
        return 'fas fa-arrow-down text-success';
      } else {
        return 'fas fa-arrow-up text-danger';
      }
    }
  },
  methods: {
    geWeightChangeBeforeSurgery() {
      let weightChangeSurgery = 0;
      let weightBeforeSurgery = null;
      if (this.patient.surgery_date !== null) {
        // find a date in weights array which is equal to or less than surgery data.
        for (let weight of this.weights) {
          if (moment(weight.date) <= moment(this.patient.surgery_date)) {
            weightBeforeSurgery = weight.weight;
            break;
          }
        }

        if (this.weights.length === 1 || weightBeforeSurgery === null) {
          weightChangeSurgery = 0;
          // weightChangeSurgery = this.patient.starting_weight - this.patient.current_weight;
          // ^ old dashboard logic for weightBeforeSurgery === null
        } else {
          weightChangeSurgery = weightBeforeSurgery - this.patient.current_weight;
        }
      }

      if (weightChangeSurgery < 0) {
        this.surgeryArrow = 'danger-arrow';
      } else if (weightChangeSurgery > 0) {
        this.surgeryArrow = 'success-arrow';
      }

      return weightChangeSurgery % 1 !== 0 ? Math.abs(+weightChangeSurgery.toFixed(2)) : Math.abs(+weightChangeSurgery);
    }
  }
}
</script>

<style scoped>
.arrow-up {
  top: 296px;
  left: 1212px;
  width: 12px;
  height: 12px;
  background: #F56036 0 0 no-repeat padding-box;
  opacity: 1;
}

.history-heading {
  color: rgb(175, 175, 175);
}

.trackers-connected-list {
  list-style: none;
  padding: 0 !important;
}

.tracker-connected {
  display: inline-block;
  margin-right: 10px;
}
</style>
