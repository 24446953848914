<template>
  <div class="row" style="padding-top: -1.5rem!important;">
    <h4 class="text-uppercase font-weight-800 ml-3">TOP 10 FOODS</h4>
    <div class="top-10-foods">
      <template v-if="data.length > 0">
        <div class="list-item-wrapper" v-for="topFood in data"
             v-bind:key="topFood.item_name">
          <div class="activity-name col-lg-7 d-flex">
            <h5 class="font-weight-600">{{ topFood.item_name }}</h5>
            <p class="top-food-count"> x {{ topFood.count }}</p>
          </div>
          <div class="col-lg-5">
            <div class="food-value-container">
              <span class="tab-name">
                  {{ topFood.name}}
              </span>
              <span class="tab-value">
                {{ Math.round(topFood.value) }}
              </span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <card class="list-item-wrapper" style="box-shadow: none!important;">
          <!--          <h4 class="text-center text-capitalize">No Data for {{ getFoodLogMonth }}</h4>-->
        </card>
      </template>
    </div>
  </div>

</template>

<script>
import googleTranslateMixin from "../../../../mixins/googleTranslateMixin";

export default {
  name: "TopFoods",
  mixins: [googleTranslateMixin],
  data() {
    return {
      data: []
    }
  },
  computed: {
    topFoods() {
      return this.$store.state.topFoods
    }
  },
  methods: {
    async translateItemsName() {
      let vm = this;
      for (let i = 0; i < this.data.length; i++) {
        let response = await this.translate([this.data[i].item_name]);
        response.json().then(data => {
          if (data && data.data && data.data.translations && data.data.translations.length > 0) {
            vm.data[i].item_name = data.data.translations[0].translatedText
          }
        })
      }
    }
  },
  watch: {
    async topFoods(newCount, oldCount) {
      this.data = [...this.$store.state.topFoods]
    }
  }
}
</script>

<style scoped>
.list-item-wrapper {
  overflow-y: hidden !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0 3px 10px;
  border-bottom: 1px solid #f1f1f1;
  text-transform: capitalize;
}

.list-item-wrapper:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
}

.top-10-foods {
  border-top: 1px solid #f1f1f1;
  padding-right: 0 !important;
  height: calc(450px - 150px);
  width: 100%;
  overflow-y: auto;
}

.food-value-container {
  min-width: 100px;
  font-size: 11px;
}

.food-value-container span {
  display: inline-block;
  text-align: right !important;
}

.tab-name {
  position: relative;
}

.tab-name::after {
  content: '';
  position: absolute;
  right: -15px !important;
  top: 7px !important;
  background-color: black;
  width: 3px;
  height: 3px;
  border-radius: 100%;
}

.tab-value {
  margin-left: 30px;
}

.top-food-count {
  text-transform: none;
  font-size: 12px;
  margin-left: 10px;
  color: #32325d;
  font-weight: 600;
  min-width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>
