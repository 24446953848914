<template>
  <div class="row doughnut-chart-row">
    <div class="doughnut-labels-wrapper" v-if="$store.state.topFoods.length > 0">
      <p v-for="(type,index) in $store.state.foodTypes" v-if="type.isData" class="doughnut-labels font-weight-600" :id="`circle${index+1}`" >{{ type.title }}</p>
    </div>
    <template v-if="$store.state.topFoods.length > 0">
      <doughnut-chart
        :height="150"
        :width="150"
        :chart-data="$store.state.doughnutChart.chartData"
        :extra-options="$store.state.doughnutChart.extraOptions"
      >
      </doughnut-chart>
    </template>
    <template v-else>
      <div>
        <h4>No Data</h4>
      </div>
    </template>
  </div>

</template>

<script>
import DoughnutChart from '@/components/Charts/DoughnutChart';

export default {
  name: "DoughnutGraph",
  components: {
    DoughnutChart,
  }
}
</script>

<style scoped>

.doughnut-chart-row {
  height: 185px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

.doughnut-labels-wrapper {
  white-space: nowrap;
  margin: 20px 0;
  padding: 0 30px 10px 20px;
  overflow-y: auto;
}

.doughnut-labels {
  max-width: 100px;
  font-size: 11px;
  margin: 0;
  padding: 0 0 3px 0;
  position: relative;
  text-transform: capitalize;
}

.doughnut-labels::before {
  content: '';
  position: absolute;
  left: -15px;
  height: 10px;
  width: 10px;
  top: 45%;
  transform: translate(-50%, -50%);
  border-radius: 100px;
}

#circle1::before {
  border: 3px solid #5E72E4;
}

#circle2::before {
  border: 3px solid #FE8C19;
}

#circle3::before {
  border: 3px solid #ECAF52;
}

#circle4::before {
  border: 3px solid #58c0e4;
}

#circle5::before {
  border: 3px solid #FF6421;
}

#circle6::before {
  border: 3px solid #94B527;
}

#circle7::before {
  border: 3px solid #8e5576;
}

#circle8::before {
  border: 3px solid #D1B490;
}

#circle9::before {
  border: 3px solid #508484;
}

#circle10::before {
  border: 3px solid #A3A380;
}

</style>
