<template>
  <div class="row">
    <div class="col-xl-12">
      <card type="default" header-classes="bg-transparent" v-loading="loader">
        <!-- Calender Heading -->
        <div slot="header" class="mb-2 mt-2 d-flex justify-content-between align-items-center">
          <div>
            <h4 class="text-uppercase ls-1 ml-1 mb-0 font-weight-900">Vitamin Compliance</h4>
          </div>
          <div class="position-relative mr-0">
            <a href="#!" class="btn btn-sm btn-neutral" @click="selectVitaminComplianceMonth">
              <i class="ni ni-calendar-grid-58"></i>
              <span v-if="request.month">{{ getComplianceMonth }}</span>
            </a>
            <el-date-picker
              class="compliancePicker"
              ref="complianceMonth"
              v-model="calendar.date"
              type="month"
              @change="changeComplianceMonth"
              placeholder="Pick a month">
            </el-date-picker>
          </div>
        </div>

        <!-- Calender -->
        <full-calendar
          :events="calendar.data"
          :plugins="calendar.plugins"
          contentHeight="auto"
          :theme="false"
          :selectable="true"
          :selectHelper="true"
          ref="fullCalendar"
          class="calendar"
          :height="800"
          :event-limit="3"
        >
        </full-calendar>
      </card>
    </div>
  </div>

</template>

<script>
import moment from "moment";
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import authHelper from "@/util/authHelper";

export default {
  name: "VitaminComplianceCard",
  props: ['patient_id', 'month', 'year', 'calendarDate'],
  components: {
    FullCalendar,
  },
  data() {
    return {
      loader: false,
      request: {
        patient_id: this.patient_id,
        month: this.month,
        year: this.year,
      },
      calendar: {
        date: null,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        data: [
          {
            title: '',
            start: '',
            end: '',
            className: '',
            allDay: true,
          },
        ],
      },
    }
  },
  watch: {
    calendarDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.request.month = this.month;
        this.request.year = this.year;
        this.calendar.date=`${this.request.month}-${this.request.year}`
        this.getVitaminCompliance();
      }
    },
    month(newVal, oldVal) {
      if (newVal!== oldVal && newVal == null) {
        this.request.month = newVal
        this.getVitaminCompliance()
      }
    }
  },
  computed: {
    getComplianceMonth() {
      return this.request.month;
    },
  },
  methods: {
    selectVitaminComplianceMonth() {
      this.$refs.complianceMonth.$el.getElementsByTagName('input')[0].focus();
    },

    calendarApi() {
      return this.$refs.fullCalendar.getApi()
    },

    changeComplianceMonth(changedMonth) {
      this.request.month = moment(changedMonth).format('MMMM');
      this.request.year = moment(changedMonth).format('YYYY');
      this.calendarApi().gotoDate(moment(`${this.request.year}-${this.request.month}-01`).format('YYYY-MM-DD'));
      this.getVitaminCompliance();
    },

    getVitaminCompliance() {
      let vm = this;
      vm.loader = true;
      axios.get(`${this.$store.getters.getBaseUrl}/api/nutrition/vitamin-compliance?patient_id=${this.request.patient_id}&month=${this.request.month}&year=${this.request.year}`).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        if (response.data.response.message == 'failed'){
          vm.$notify.info({
            title: 'Vitamin Compliance',
            message: 'Something went wrong please try again.'
          })
        }else {
          vm.processVitaminCompliance(response.data.response.data)
        }

        vm.loader = false;
      }).catch(error => {
        // console.log(error);
        vm.loader = false;
      })
    },

    setCellColor(products, product) {
      let color = '';
      if (products[product].status === 'missed') {
        color = 'red';
      } else if (products[product].status === 'taken') {
        color = 'green';
      } else {
        color = 'blue';
      }
      return color;
    },

    updateCalendar(dates, date, color) {
      this.calendar.data.push({
        title: 'Product',
        start: `${date}`,
        end: `${date}`,
        className: `background-${color}`,
        allDay: true,
        rendering: 'background'
      });
    },

    decideDayColor(status, color){
      if(status == 'pending'){
        color = 'grey';
      }else if(status == 'missed' && color == 'default'){
        color = 'red';
      }else if(status == 'missed' && color == 'green'){
        color = 'yellow';
      }else if(status == 'taken' && color == 'default'){
        color = 'green';
      }else if(status == 'taken' && color == 'red'){
        color = 'yellow';
      }

      return color;
    },

// Update Vitamin Compliance
    processVitaminCompliance(dates) {
      this.calendar.data = [];
      for (let date in dates) {
        var color = 'default';
        if (dates.hasOwnProperty(date)) {
          let products = dates[date][0].products;
          for (let product in products) {
            if (products.hasOwnProperty(product) && product != 'baritastic') {
              this.calendar.data.push({
                title: product,
                start: `${date}`,
                end: `${date}`,
                className: this.setCellColor(products, product),
                allDay: true
              });

              color = this.decideDayColor(products[product].status, color);
            }
          }

          // process baritastic reminders

          if (products.baritastic.length > 0 ){
            for (let product_bari in products.baritastic) {
              for (let product in products.baritastic[product_bari]) {
                this.calendar.data.push({
                  title: product,
                  start: `${date}`,
                  end: `${date}`,
                  className: this.setCellColor(products.baritastic[product_bari], product),
                  allDay: true
                });
                color = this.decideDayColor(products.baritastic[product_bari][product].status, color);
              }
            }
          }
          this.updateCalendar(dates, date, color == 'default' ? 'grey' : color);
        }
      }
    }
  },
  mounted() {
    this.getVitaminCompliance();
  }
}
</script>

<style>
.current-month {
  position: relative;
}

.fc-more-popover .fc-event-container{
  max-height: 300px !important;
  overflow: auto !important;
}

.compliancePicker {
  position: absolute;
  left: -180px !important;
}
</style>
