export default {
  methods: {
    /**
     * Generate Export File download
     * @param response
     * @param fileName
     */
    generatePdfFileDownload(response, fileName) {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName + `.pdf`;
      link.click();
    },
  }
}
