<template>
  <div class="col-xl-6">
    <card v-loading="loader">
      <template v-slot:header>
        <div class="row align-items-center">
          <div class="col-8">
            <!-- Title -->
            <h4 class="h4 mb-0 font-weight-800 text-uppercase">Steps</h4>
            <!-- Subtitle -->
            <h5 v-if="request.month" class="surtitle mt-1 font-weight-400 text-capitalize ls-0">{{ getStepsDate }}</h5>
          </div>
          <div class="col-4 text-right">
            <!-- Subtitle -->
            <h5 class="surtitle mt-1 font-weight-400 text-capitalize ls-0">Average</h5>
            <!-- Title -->
            <h2 class="h2 mb-0 font-weight-800 text-uppercase">{{ stepsChart.average }}<span
              class="surtitle font-weight-300 ml-1 heading-sur">steps</span></h2>
          </div>
        </div>
      </template>
      <div class="chart">
        <bar-chart
          :chart-data="stepsChart.chartData"
          :extra-options="stepsChart.extraOptions"
          :height="350"
        />
      </div>
    </card>
  </div>

</template>

<script>
import moment from "moment";
import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from "@/components/Charts/config";
import authHelper from "@/util/authHelper";

export default {
  name: "StepsChartCard",
  props: ['patient_id', 'month', 'year', 'calendarDate'],
  components: {
    BarChart,
  },
  data() {
    return {
      loader: false,
      request: {
        patient_id: this.patient_id,
        month: this.month,
        year: this.year,
      },
      date: `${moment().format('YYYY')}-${moment().format('MM')}`,
      stepsChart: {
        average: 0,
        chartData: {
          datasets: [{
            label: 'Steps',
            data: [],
            backgroundColor: "#426A87",
          }],
          labels: [],
        },
        extraOptions: chartConfigs.orangeFill,
      },
    }
  },
  watch: {
    calendarDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.request.month = this.month;
        this.request.year = this.year;
        this.date=`${this.request.month}-${this.request.year}`
        this.getSteps();
      }
    },
    month(newVal, oldVal) {
      if (newVal!== oldVal && newVal == null) {
        this.request.month = newVal
        this.getSteps()
      }
    }
  },
  computed: {
    getStepsDate() {
      return moment(`${this.date}`).format('MMMM, YYYY');
    }
  },
  methods: {
    average(sum, total) {
      return total === 0 ? 0 : sum / total;
    },

    getSteps() {
      let vm = this;
      vm.loader = true;
      axios.get(this.$store.getters.getBaseUrl + `/api/nutrition/daily-steps?patient_id=${this.request.patient_id}&month=${this.request.month}&year=${this.request.year}`).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.processStepsChart(response.data.data);
      }).catch(error => {
        if (error.response.status != 401) {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Daily Steps',
            message: message
          });
        }
      }).finally(() => {
        vm.loader = false;
      });
    },

    processStepsChart(steps) {
      let data = [], labels = [], sum = 0, dayCount = 0;
      for (let interval of steps.interval) {
        const step = steps.daily_steps.find(step => step.logged_at === interval)
        step ? data.push(Math.round(step.benefit)) : data.push(0);
        step ? sum += +step.benefit : sum += 0;
        step ? dayCount += 1 : dayCount += 0;
        labels.push(interval);
      }
      this.updateStepsChart(sum, dayCount, data, labels);
    },

    updateStepsChart(sum, dayCount, data, labels) {
      this.stepsChart.average = Math.round(this.average(sum, dayCount));
      this.stepsChart.chartData = {
        datasets: [{
          label: 'Steps',
          data: data,
          backgroundColor: "#426A87",
        }],
        labels: labels,
      }
    }
  },
  mounted() {
    this.getSteps();
  }
}
</script>

<style scoped>

</style>
