<template>
  <!-- Food Log Header -->
  <div class="row d-flex flex-row justify-content-between align-items-center">
    <div class="col-xl-8 col-lg-12 pl-0">
      <!-- Food Log Heading -->
      <h4 class="text-uppercase ls-1 mb-0 ml-4 font-weight-900">Nutrition</h4>
      <!-- Food Log Tabs -->
      <ul class="switchers pl-4 pt-3 mb-0 pb-0 justify-content-between">
        <div class="switchers">
          <li class="food-log-switcher"
              v-for="item in $store.state.foodLogTabs.tabs"
              :class="{'active-food-switcher': $store.state.foodLogTabs.activeTabId === item.id}"
              v-bind:key="item.id"
              :id="`${item.name.toLowerCase()}-switcher`"
              @click="changeFoodLogTab(item.id)"
          >
            {{ item.name }}
          </li>
        </div>
        <div>
          <li class="nutrition-average mt--3 ml-3">
            <h5 class="surtitle mt-1 font-weight-400 text-capitalize ls-0">Average</h5>
            <h2 class="h2 mb-0 font-weight-800 text-uppercase">{{ getFoodLogAverage }}
              <span v-if="$store.state.foodLogTabs.activeTab.name === 'Water'" class="surtitle font-weight-300 heading-sur">{{'oz'}}</span>
              <span v-else class="surtitle font-weight-300 heading-sur">{{$store.state.foodLogTabs.activeTab.name === 'Calories' ? 'cal':'gms' }}</span>
            </h2>
          </li>
        </div>
      </ul>
      <!-- Food Log Tabs Finished -->
    </div>
    <!-- Food Log Month Picker -->
    <div class="col-xl-4 col-lg-12 col-md-12 mt-sm-4 mt-md-4 mt-lg-4 divCalendar">
      <div class="position-relative float-right">
        <a href="#!" class="btn btn-sm btn-neutral" @click="selectFoodMonth">
          <i class="ni ni-calendar-grid-58"></i> {{ getFoodLogMonth }}
        </a>
        <el-date-picker
          class="foodLogPicker"
          ref="foodLogPicker"
          v-model="date"
          type="month"
          @change="changeFoodLogMonth"
          placeholder="Pick a month">
        </el-date-picker>

        <router-link :to="{name: 'FoodLog', query: patient_id}">
          <base-button class="btn-secondary-action">
            Food Logs
          </base-button>
        </router-link>

      </div>
    </div>
    <!-- Food Log Month Picker Finished -->
  </div>
  <!-- Food Log Header Finished -->

</template>

<script>
import moment from "moment";

export default {
  name: "FoodLogHeader",
  props: ['patient_id', 'month', 'year', 'calendarDate'],
  data() {
    return {
      loader: false,
      request: {
        patient_id: this.patient_id,
        month: this.month,
        year: this.year,
      },
      date: null,
    }
  },
  computed: {
    getFoodLogMonth() {
      return this.request.month;
    },
    getFoodLogAverage() {
      return Math.round(+this.$store.state.foodLogChart.average);
    }
  },
  watch: {
    calendarDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.request.month = this.month;
        this.request.year = this.year;
        this.date=`${this.request.month}-${this.request.year}`
      }
    },
    month(newVal, oldVal) {
      if (newVal!== oldVal && newVal == null) {
        this.request.month = newVal
      }
    }
  },
  methods: {
    selectFoodMonth() {
      this.$refs.foodLogPicker.$el.getElementsByTagName('input')[0].focus();
      if(this.$store.state.foodLogTabs.activeTabId===7) {
        this.$emit('onWaterTabClick',{month: this.request.month, year: this.request.year});
      }
    },

    changeFoodLogMonth() {
      this.request.month = moment(this.date).format('MMMM');
      this.request.year = moment(this.date).format('YYYY');
      this.$emit('onMonthChange', {month: this.request.month, year: this.request.year});
    },

    changeFoodLogTab(id) {
      this.$store.commit('setFoodLogTab', id);
      if(id===7){
        this.$emit('onWaterTabClick');
      }else{
        this.$store.commit('updateFoodTypes', this.$store.state.foodLogs.mealTypesOrder);
        this.$store.commit('updateFoodLogChart', this.$store.state.foodLogs);
      }

    },

  }
}
</script>

<style scoped>
.switchers {
  list-style: none;
  padding: 0;
  display: flex;
}

.active-food-switcher {
  opacity: 1 !important;
}

.food-log-switcher {
  position: relative;
  cursor: pointer;
  padding-top: 15px;
  opacity: 0.5;
  transition: all 0.5s ease;
}

.food-log-switcher::before {
  content: '';
  position: absolute;
  top: -1px;
  width: 36px;
  height: 8px;
  border-radius: 20px !important;
}

.food-log-switcher:not(:first-child) {
  margin-left: 50px;
}

/* Dynamic ID's */

#calories-switcher::before {
  background-color: #5E72E4;
}

#carb-switcher::before {
  background-color: #FE8C19;
}

#protein-switcher::before {
  background-color: #ECAF52;
}

#fat-switcher {
  margin-left: 45px;
}

#fat-switcher::before {
  background-color: #FFCD16;
}

#sugar-switcher {
  margin-left: 60px;
}

#sugar-switcher::before {
  background-color: #FF6421;
}

#fiber-switcher::before {
  background-color: #94B527;
}

#water-switcher::before {
  background-color: #68C0EF;
}

/* Dynamic ID's Finished */

.foodLogPicker {
  position: absolute;
  left: -100px !important;
}

.divCalendar {

}

</style>
