<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4">
      <route-back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <push-notification :notificationModal="notificationModal" @closeNotificationModal="notificationModal=$event"/>
    <pdf-report-download-modal
      :show-modal="pdfReportDownloadModal"
      :loader="loading.pdfReport"
      @closeNotificationModal="pdfReportDownloadModal=$event"
      @loading="loading.pdfReport=$event"
      @getReport="getPdfReport"
    ></pdf-report-download-modal>
    <!-- Page Heading -->
    <div class="nutrition-report-page row justify-content-between page-header-row m-0 pl-4 pr-4 pt-3">
      <div class="heading-container">
        <h1 class="ml-2 patient-name font-weight-600">
          Nutrition Report
        </h1>
      </div>
      <div class="header-buttons d-flex justify-content-center align-items-center">
        <div class="position-relative">
          <el-date-picker
            class="monthPicker"
            ref="monthPicker"
            v-model="monthPicker.selectedDate"
            type="month"
            @change="changeMonth"
            placeholder="Pick a month">
          </el-date-picker>
          <base-nav id="datepicker-dropdown">
            <base-dropdown id="dropdown-menu" menu-on-right>
              <a href="#" class="nav-link" @click.prevent slot="title-container">
                <div class="btn-secondary-action title-btn-padding button-hover-effect button-font">
                  <i v-if="request.month" class="mr-2 ni ni-calendar-grid-58"></i>
                  <span v-if="request.month">{{ this.getMonthDate }}</span>
                  <span v-else>Last 30 Days</span>
                  <span class="ml-2 fa fa-chevron-down"></span>
                </div>
              </a>
              <a href="#" class="dropdown-item" @click.prevent="default30Days">
                <div class="d-flex title-btn-padding button-font">
                  <span>Last 30 Days</span>
                </div>
              </a>
              <a href="#" @click.prevent="triggerMonthPicker" class="dropdown-item">
                <div class=" d-flex title-btn-padding button-font">
                  <i class="mr-2 white-outline-button ni ni-calendar-grid-58"></i>
                  <span>{{ this.getMonthDate }}</span>
                </div>
              </a>
            </base-dropdown>
          </base-nav>
        </div>
        <router-link class="mr-2 d-flex" :to="{name: 'WeightLog', query: patient_id}">
          <base-button class="btn-secondary-action title-btn-padding button-hover-effect button-font">
            Weight Logs
          </base-button>
        </router-link>
        <router-link class="mr-2 d-flex" :to="{name: 'FoodLog', query: patient_id}">
          <base-button class="btn-secondary-action title-btn-padding button-hover-effect button-font">
            Food Logs
          </base-button>
        </router-link>
        <base-button @click="notificationModal=true" class="mr-2 btn-secondary-action title-btn-padding button-hover-effect button-font">Message</base-button>
        <base-button @click="pdfReportDownloadModal=true" class="mr-2 btn-secondary-action title-btn-padding button-hover-effect button-font">PDF Report</base-button>
      </div>
    </div>

    <!-- App, Surgery & Health Tracker Section. -->
    <div class="row m-0 pl-3 pr-3 pt-3">
      <app-card :loader="loading.app" :patient="status.patient" :last-food-log="status.lastFoodLog" :total-food-logs="status.totalFoodLog"/>
      <surgery-card
        :loader="loading.app"
        :patient="status.patient"
        :weights="status.weight"
        :health-trackers="status.healthTrackers"
      />
    </div>

    <!--  Graph Wrapper & Graph  -->
    <div class="container-fluid mt-1">
      <weight-card :patient_id="request.patient_id" :month="request.month" :year="request.year"
                   :calendar-date="request.calendarDate"/>
    </div>

    <div class="container-fluid mt-1">
      <!-- Food Log Graph Row -->
      <card type="default" header-classes="bg-transparent" class="food-log-graphs-card" v-loading="loading.foodLog">
        <div slot="header">
          <food-log-header :month="request.month" :year="request.year"
                           :calendar-date="request.calendarDate"
                           :patient_id="request.patient_id"
                           @onMonthChange="changeFoodLogMonth"
                           @onWaterTabClick="getWaterLogs(request.month,request.year)"
                           @onFoodTabClick="getFoodLogs(request.month,request.year)"

          />
        </div>

        <!-- Food Log Graph Rows -->
        <div class="row">
          <!-- Food Log Graph Col -->
          <food-bar-graph/>
          <!-- Food Log Top 10 Foods Column and Doughnut Chart -->
          <div class="col-xl-4 doughnut-graph">
            <!-- Food Log Doughnut Chart -->
            <doughnut-graph/>
            <!-- Food Log Doughnut Chart Finished. -->

            <!-- Food Log Top 10 Foods. -->
            <top-foods/>
            <!-- Food Top 10 Foods Finished -->
          </div>
          <!-- Food Log Top 10 Foods Column and Doughnut Chart Finished -->
        </div>
      </card>
      <div class="row">
        <div class="col-lg-6" v-loading="loading.macroNutrients">
          <macro-nutrients-pie-chart
            :nutrient-data="nutrientData"
          />
        </div>
        <div class="col-lg-6">
          <notes screen="NutritionReport"/>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-1">
      <div class="row">
        <sleep-chart-card :patient_id="request.patient_id" :month="request.month" :year="request.year"
                          :calendar-date="request.calendarDate"/>
        <activity-card :patient_id="request.patient_id" :month="request.month" :year="request.year"
                       :calendar-date="request.calendarDate"/>
      </div>

      <div class="row">
        <calories-burned-chart :patient_id="request.patient_id" :month="request.month" :year="request.year"
                               :calendar-date="request.calendarDate"/>
        <steps-chart-card :patient_id="request.patient_id" :month="request.month" :year="request.year"
                          :calendar-date="request.calendarDate"/>
      </div>

    </div>
    <!--  Calender Wrapper & Calender  -->
    <div class="container-fluid mt-1">
      <!-- Calender Row -->
      <vitamin-compliance-card :patient_id="request.patient_id" :month="request.month" :year="request.year"
                               :calendar-date="request.calendarDate"/>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

// Generic Components
import BackButton from "@/components/Router/BackButton";
import PushNotification from "@/views/Components/Modals/PushNotification";
import PdfReportDownloadModal from "@/views/Components/Modals/PdfReportDownloadModal";
import pdfFileDownload from "@/mixins/pdfFileDownload";
// Nutrition Components.
import AppCard from "@/views/Components/NutritionReport/AppCard";
import SurgeryCard from "@/views/Components/NutritionReport/SurgeryCard";
import WeightCard from "@/views/Components/NutritionReport/WeightCard";
import VitaminComplianceCard from "@/views/Components/NutritionReport/VitaminComplianceCard";
import SleepChartCard from "@/views/Components/NutritionReport/SleepChartCard";
import ActivityCard from "@/views/Components/NutritionReport/ActivityCard";
import CaloriesBurnedChart from "@/views/Components/NutritionReport/CaloriesBurnedChart";
import StepsChartCard from "@/views/Components/NutritionReport/StepsChartCard";
import FoodLogHeader from "@/views/Components/NutritionReport/FoodLog/FoodLogHeader";
import FoodBarGraph from "@/views/Components/NutritionReport/FoodLog/FoodBarGraph";
import DoughnutGraph from "@/views/Components/NutritionReport/FoodLog/DoughnutGraph";
import TopFoods from "@/views/Components/NutritionReport/FoodLog/TopFoods";
import MacroNutrientsPieChart from "@/views/Components/NutritionReport/MacroNutrientsPieChart";

import authHelper from "@/util/authHelper";
import Notes from "@/views/Components/Notes/Notes";

export default {
  name: "NutritionReport",
  components: {
    PushNotification,
    PdfReportDownloadModal,
    StepsChartCard,
    Notes,
    RouteBackButton: BackButton,
    AppCard,
    SurgeryCard,
    WeightCard,
    VitaminComplianceCard,
    SleepChartCard,
    ActivityCard,
    CaloriesBurnedChart,
    FoodLogHeader,
    FoodBarGraph,
    DoughnutGraph,
    TopFoods,
    MacroNutrientsPieChart
  },
  data() {
    return {
      request: {
        patient_id: +this.$route.params.id,
        month: null,
        year: moment().format('YYYY'),
        calendarDate: '',
      },
      nutrientData: [],
      loading: {
        app: false,
        weight: false,
        notification: false,
        weightChart: false,
        activities: false,
        sleepChart: false,
        caloriesChart: false,
        stepsChart: false,
        foodLog: false,
        macroNutrients: false,
        pdfReport: false,
      },
      patient_id: 1,

      // Notification
      notification: {
        patient_id: '',
        notification: '',
      },

      status: {
        patient: {
          last_used: null,
          surgery_date: null,
        },
        weight: [],
        foodLog: [],
        healthTrackers: [],
        lastFoodLog:'N/A',
        totalFoodLog:0
      },

      monthPicker: {
        selectedDate: `${moment().format('YYYY')}-${moment().format('MM')}`,
      },

      foodLogPicker: {
        selectedDate: `${moment().format('YYYY')}-${moment().format('MM')}`,
      },

      // Notification Modal
      notificationModal: false,
      pdfReportDownloadModal: false,
    }
  },
  mixins: [
    pdfFileDownload
  ],
  computed: {
    getFoodLogMonth() {
      return this.$options.filters.fullMonthName(this.date);
    },

    getMonthDate() {
      return this.$options.filters.fullMonthWithYear(this.monthPicker.selectedDate);
    },
  },
  methods: {
    getPdfReport(type) {
      let vm = this
      vm.loading.pdfReport = true
      const params = {
        patientId: vm.request.patient_id,
        type
      }
      vm.$store.dispatch('_getPatientPdfReport', params)
      .then(response => {
        vm.generatePdfFileDownload(response, 'Nutrition Report')
        vm.pdfReportDownloadModal = false
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Nutrition Report',
          message: message
        })
      })
      .finally(() => {
        vm.loading.pdfReport = false
      })
    },

    // Calculates Average
    average(sum, total) {
      return total === 0 ? 0 : sum / total;
    },

    triggerMonthPicker() {
      this.$refs.monthPicker.$el.getElementsByTagName('input')[0].focus();
    },

    default30Days() {
      this.request.month = null
      this.getData(null, moment().format('YYYY'));
    },

    changeFoodLogMonth(event) {
      if (this.$store.state.foodLogTabs.activeTabId !== 7) {
        this.getFoodLogs(event.month, event.year);
        this.getMacroNutrients(event.month, event.year)
      } else {
        this.getWaterLogs(event.month, event.year)
      }
    },

    // Call Graph Api's
    getData(month, year) {
      this.getAppStatus();
      this.getFoodLogs(month, year);
      this.getMacroNutrients(month, year)
    },

    setPatientId() {
      this.patient_id = +this.$route.params.id;
      this.notification.patient_id = +this.$route.params.id;
    },

    changeMonth(date) {
      this.request.month = moment(date).format('MMMM');
      this.request.year = moment(date).format('YYYY');
      this.request.calendarDate = date;
      this.getData(this.request.month, this.request.year);
    },

    getAppStatus() {
      let vm = this;
      vm.loading.app = true;
      axios.get(`${this.$store.getters.getBaseUrl}/api/nutrition/app-status?patient_id=${this.patient_id}`).then(response => {
        vm.status = response.data.data;
      }).catch(error => {
      }).finally(() => {
        vm.loading.app = false
      });
    },

    getFoodLogs(month, year) {
      let vm = this;
      vm.loading.foodLog = true;
      this.$store.dispatch('_getFoodLogs', {
        patient_id: this.patient_id,
        month,
        year,
      }).finally(() => {
        vm.loading.foodLog = false;
      });
    },

    getMacroNutrients(month, year) {
      let vm = this
      vm.loading.macroNutrients = true
      const payload = {
        patient_id: vm.patient_id,
        month,
        year,
      }
      vm.$store.dispatch('_getMacroNutrients', payload)
      .then(response => {
        vm.nutrientData.splice(0, vm.nutrientData.length, ...response.data.data)
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Macro-Nutrients Pie Chart',
          message: message
        })
      })
      .finally(() => {
        vm.loading.macroNutrients = false
      })
    },

    getWaterLogs(month = moment().format('MMMM'), year = moment().format('YYYY')) {
      month = month ? moment().month(month).format('MM') : month
      let vm = this;
      vm.loading.foodLog = true;
      this.$store.dispatch('_getWaterLogs', {
        patientId: this.request.patient_id,
        month,
        year,
      }).finally(() => {
        vm.loading.foodLog = false;
      });
    },
  },

  mounted() {
    this.setPatientId();
    this.getData(null, moment().format('YYYY'));
  }
}
</script>

<style scoped>

#datepicker-dropdown >>> .container{
  padding: 0 9px 0 9px;
}

nav.navbar, a.nav-link{
  padding: 0;
}

#dropdown-menu >>> .dropdown-menu{
  margin-top: 8px;
}

/* Page Styles */
.page-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-buttons {
  position: relative;
  display: flex;
  flex-direction: row;
}

.message-modal-text {
  margin-top: -35px;
}

.doughnut-graph {
  border-left: 1px solid #f1f1f1;
}

.heading-sur {
  font-size: 10px;
  text-transform: lowercase;
  letter-spacing: 0;
}

.monthPicker {
  position: absolute;
  left: -100px !important;
}


</style>

<style lang="scss">
/* Calender Stylesheet import */
@import "~@fullcalendar/core/main.css";
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@/assets/sass/core/vendors/fullcalendar';
@import "./nutrition-report.css";
</style>
