<template>
  <div class="col-xl-6">
    <card v-loading="loader">
      <template v-slot:header>
        <div class="row align-items-center">
          <div class="col-8">
            <!-- Title -->
            <h4 class="h4 mb-0 font-weight-800 text-uppercase">Calories burned</h4>
            <!-- Subtitle -->
            <h5 v-if="request.month" class="surtitle mt-1 font-weight-400 text-capitalize ls-0">
              {{ getCaloriesBurnedDate }}
            </h5>
          </div>
          <div class="col-4 text-right">
            <!-- Subtitle -->
            <h5 class="surtitle mt-1 font-weight-400 text-capitalize ls-0">Average</h5>
            <!-- Title -->
            <h2 class="h2 mb-0 font-weight-800 text-uppercase">{{ caloriesBurnedChart.average }}
              <span class="surtitle font-weight-300 ml-1 heading-sur">cal</span></h2>
          </div>
        </div>
      </template>
      <div class="chart">
        <bar-chart
          :chart-data="caloriesBurnedChart.chartData"
          :extra-options="caloriesBurnedChart.extraOptions"
          :height="350"
        />
      </div>
    </card>
  </div>

</template>

<script>
import moment from "moment";
import * as chartConfigs from "@/components/Charts/config";
import BarChart from '@/components/Charts/BarChart';
import authHelper from "@/util/authHelper";

export default {
  name: "CaloriesBurnedChart",
  props: ['patient_id', 'month', 'year', 'calendarDate'],
  components: {
    BarChart,
  },
  data() {
    return {
      loader: false,
      request: {
        patient_id: this.patient_id,
        month: this.month,
        year: this.year,
      },
      date: `${moment().format('YYYY')}-${moment().format('MM')}`,
      caloriesBurnedChart: {
        average: 0,
        chartData: {
          labels: [],
          datasets: [{
            label: 'Calories Burned',
            data: [],
            backgroundColor: "#BF93A2",
          }]
        },
        extraOptions: chartConfigs.orangeFill,
      },
    }
  },
  watch: {
    calendarDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.request.month = this.month;
        this.request.year = this.year;
        this.date=`${this.request.month}-${this.request.year}`
        this.getCaloriesBurned();
      }
    },
    month(newVal, oldVal) {
      if (newVal!== oldVal && newVal == null) {
        this.request.month = newVal
        this.getCaloriesBurned()
      }
    }
  },
  computed: {
    getCaloriesBurnedDate() {
      return moment(`${this.date}`).format('MMMM, YYYY');
    },
  },
  methods: {
    average(sum, total) {
      return total === 0 ? 0 : sum / total;
    },

    getCaloriesBurned() {
      let vm = this;
      vm.loader = true;
      axios.get(this.$store.getters.getBaseUrl + `/api/nutrition/calories-burned?patient_id=${this.request.patient_id}&month=${this.request.month}&year=${this.request.year}`).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.processCaloriesChart(response.data.data);
      }).catch(error => {
        if (error.response.status != 401) {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Calories',
            message: message
          });
        }
        vm.loader = false;
      }).finally(() => {
        vm.loader = false;
      });
    },

    processCaloriesChart(calories) {
      let data = [], labels = [], sum = 0, dayCount = 0;
      let keys = Object.keys(calories.calories_burned);
      for (const interval of calories.interval) {
        const calorieFound = keys.find(date => date === interval);
        calorieFound ? data.push(Math.round(calories.calories_burned[calorieFound].benefit)) : data.push(0);
        calorieFound ? sum += +calories.calories_burned[calorieFound].benefit : sum += 0;
        calorieFound ? dayCount += 1 : dayCount += 0;
        labels.push(interval);
      }
      this.updateCaloriesChart(sum, dayCount, data, labels);
    },

    updateCaloriesChart(sum, dayCount, data, labels) {
      this.caloriesBurnedChart.average = Math.round(this.average(sum, dayCount));
      this.caloriesBurnedChart.chartData = {
        datasets: [{
          label: 'Calories Burned',
          data: data,
          backgroundColor: "#BF93A2",
        }],
        labels: labels,
      }
    }
  },

  mounted() {
    this.getCaloriesBurned();
  }
}
</script>

<style scoped>

</style>
