<template>
  <div class="col-xl-6">
    <card v-loading="loader">
      <template v-slot:header>
        <div class="row align-items-center">
          <div class="col-6">
            <!-- Title -->
            <h4 class="h4 mb-0 font-weight-800 text-uppercase">Sleep</h4>
            <!-- Subtitle -->
            <h5 v-if="request.month" class="surtitle mt-1 font-weight-400 text-capitalize ls-0">{{ getSleepDate }}</h5>
          </div>
          <div class="col-6 text-right d-flex flex-row align-content-center justify-content-end">
            <div class="sleep-action mr-3">
              <!-- Subtitle -->
              <h5 class="surtitle mt-1 font-weight-400 text-capitalize ls-0">Average</h5>
              <!-- Title -->
              <h2 class="h2 mb-0 font-weight-800 text-capitalize">
                {{ sleepChart.average.hours }}
                <span class="surtitle font-weight-300 heading-sur">hr</span>
                {{ sleepChart.average.minutes }}
                <span class="surtitle font-weight-300 heading-sur">min</span>
              </h2>
            </div>
            <div class="position-relative mt-2">
              <a href="#!" class="btn btn-sm btn-neutral" @click="selectSleepMonth">
                <i class="ni ni-calendar-grid-58"></i> {{ getSleepMonth }}
              </a>
              <el-date-picker
                class="sleepPicker"
                ref="sleepPicker"
                v-model="date"
                type="month"
                @change="changeSleepMonth"
                placeholder="Pick a month">
              </el-date-picker>
            </div>
          </div>
        </div>
      </template>
      <div class="chart">
        <line-chart
          :height="350"
          :chart-data="sleepChart"
          :extra-options="sleepChart.extraOptions"
        >
        </line-chart>
      </div>
    </card>
  </div>
</template>

<script>
import LineChart from '@/components/Charts/LineChart';
import authHelper from "@/util/authHelper";
import * as chartConfigs from "@/components/Charts/config";
import moment from "moment";

export default {
  name: "SleepChartCard",
  props: ['patient_id', 'month', 'year', 'calendarDate'],
  components: {
    LineChart,
  },
  data() {
    return {
      loader: false,
      request: {
        patient_id: this.patient_id,
        month: this.month,
        year: this.year,
      },
      date: null,
      sleepChart: {
        average: {
          hours: 0,
          minutes: 0,
        },
        chartData: {},
        extraOptions: chartConfigs.sleepGraph,
      }
    }
  },
  watch: {
    calendarDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.request.month = this.month;
        this.request.year = this.year;
        this.date=`${this.request.month}-${this.request.year}`
        this.getSleep();
      }
    },
    month(newVal, oldVal) {
      if (newVal!== oldVal && newVal == null) {
        this.request.month = newVal
        this.getSleep()
      }
    }
  },
  computed: {
    getSleepDate() {
      return moment(`${this.date}`).format('MMMM, YYYY');
    },

    getSleepMonth() {
      return this.request.month;
    },
  },
  methods: {
    selectSleepMonth() {
      this.$refs.sleepPicker.$el.getElementsByTagName('input')[0].focus();
    },

    average(sum, total) {
      return total === 0 ? 0 : sum / total;
    },

    changeSleepMonth() {
      this.request.month = moment(this.date).format('MMMM');
      this.request.year = moment(this.date).format('YYYY');
      this.getSleep();
    },

    getSleep() {
      let vm = this;
      vm.loader = true;
      axios.get(this.$store.getters.getBaseUrl + `/api/nutrition/sleeps?patient_id=${this.request.patient_id}&month=${this.request.month}&year=${this.request.year}`).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.processSleepChart(response.data.data);
      }).catch(error => {
        if (error.response.status != 401) {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Sleep',
            message: message
          });
        }
      }).finally(() => {
        vm.loader = false;
      });
    },

    processSleepChart(sleeps) {
      let data = [], labels = [], sum = 0, dayCount = 0, average = 0;
      for (let interval of sleeps.interval) {
        const sleep = sleeps.sleep_data.find(sleep => sleep.sleep_date === interval)
        sleep ? data.push(Math.round(sleep.hrs_sleep)) : data.push(0);
        sleep ? sum += +Math.round(sleep.hrs_sleep) : sum += 0;
        sleep ? dayCount += 1 : dayCount += 0;
        labels.push(interval);
      }
      average = this.average(sum, dayCount).toFixed(2);
      this.updateSleepChart(average, data, labels);
    },

    updateSleepChart(average, data, labels) {
      this.sleepChart = {
        average: {
          hours: average.split('.')[0],
          minutes: Math.round(`${average.split('.')[1]}` * 0.6),
        },
        datasets: [
          {
            label: 'Sleep',
            data: data,
            borderColor: "#3F5369",
            pointBackgroundColor: "#788695",
            pointBorderColor: "#FFFFFF",
            pointBorderWidth: 3,
            pointStrokeColor: "#fff",
            pointHitRadius: 1,
            pointRadius: 5,
          }
        ],
        labels: labels,
        extraOptions: chartConfigs.sleepGraph
      }
    }

  },
  mounted() {
    this.getSleep();
  }
}
</script>

<style scoped>
.sleep-action {
  margin: 0 0 0 15px;
}

.sleepPicker {
  position: absolute;
  left: -100px !important;
}
</style>
