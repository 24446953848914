<template>
  <div class="col-xl-8 food-log-graph">
    <bar-chart
      :chart-data="$store.state.foodLogChart.chartData"
      :extra-options="$store.state.foodLogChart.extraOptions"
      :height="450"
    />
  </div>
</template>

<script>
import BarChart from '@/components/Charts/BarChart';

export default {
  name: "FoodBarGraph",
  components: {
    BarChart,
  }
}
</script>

<style scoped>
.food-log-graph {
  padding: 30px 10px 30px 10px;
}
</style>
