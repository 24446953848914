<template>
  <div class="row">
    <div class="col-xl-12">
      <card type="default" header-classes="bg-transparent" v-loading="loader">
        <!-- Graph Heading -->
        <div slot="header" class="row align-items-center justify-content-between">
          <div class="float-left">
            <h4 class="text-uppercase ls-1 mb-0 ml-3 font-weight-700">{{  chartLabel }}</h4>
          </div>
          <div class="d-flex align-items-center float-right mr-4">
            <span  @click="heightWarning" style="height: 24px;" class="d-flex align-items-center">
            <span class="active-text pl-3">WEIGHT</span>
              <base-switch
                class="weight-bmi-switch status-toggle mx-2"
                onText=""
                offText=""
                v-model="bmiChart"
                :disabled="this.response.height ? false : true"
              >
              </base-switch>
              <span class="active-text pr-3">BMI</span>
            </span>
            <router-link :to="{name: 'WeightLog', query: patient_id}">
              <base-button class="btn-secondary-action">
                Weight Logs
              </base-button>
            </router-link>

          </div>
        </div>
        <!-- Graph -->
        <line-chart
          :height="350"
          ref="bigChart"
          :chart-data="weightChart.chartData"
          :extra-options="weightChart.extraOptions"
        >
        </line-chart>
      </card>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/Charts/LineChart';
import * as chartConfigs from "@/components/Charts/config";

export default {
  name: "WeightCard",
  props: ['patient_id', 'month', 'year', 'calendarDate'],
  components: {
    LineChart,
  },
  data() {
    return {
      loader: false,
      request: {
        patient_id: this.patient_id,
        month: this.month,
        year: this.year,
      },
      weightChart: {
        chartData: {},
        extraOptions: chartConfigs.equalDistribution,
      },
      response: {
        interval: [],
        weights: [],
        height: 0
      },
      bmiChart: false // it can be WEIGHT or BMI
    }
  },
  watch: {
    calendarDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.request.month = this.month;
        this.request.year = this.year;
        this.date = `${this.request.month}-${this.request.year}`
        this.getWeights();
      }
    },
    month(newVal, oldVal) {
      if (newVal!== oldVal && newVal == null) {
        this.request.month = newVal
        this.getWeights()
      }
    },
    bmiChart(newVal, oldVal) {
      this.processWeightGraph(this.response);
    }
  },
  computed: {
    chartLabel() {
      return this.bmiChart ? 'BMI' : 'Weight'
    }
  },
  methods: {
    heightWarning() {
      if (!this.response.height) {
        this.$notify.warning({
          title: 'BMI',
          message: "Patient didn't added height"
        });
      }

    },
    getWeights() {
      let vm = this;
      vm.loader = true;
      axios.get(this.$store.getters.getBaseUrl + `/api/nutrition/weights?patient_id=${this.request.patient_id}&month=${this.request.month}&year=${this.request.year}`).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.response = response.data.data;
        vm.processWeightGraph(response.data.data);
      }).catch(error => {
        if (error.response.status != 401) {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Weight',
            message: message
          });
        }
      }).finally(() => {
        vm.loader = false;
      });
    },

    processWeightGraph(weights) {
      let data = [], labels = [];

      if (weights.weights.length > 1) {
        for (let weight of weights.weights) {
          let value = this.getWeightBMIValue(weight.weight);
          data.push(value)
          labels.push(weight.date)
        }
      } else {
        for (let interval of weights.interval) {
          const weight = weights.weights.find(weight => weight.date === interval)
          if (weight) {
            let value = this.getWeightBMIValue(weight.weight);
            data.push(value)
          }
          labels.push(interval);
        }
      }

      this.updateWeightGraph(data, labels);
    },

    getWeightBMIValue(weight) {
      return this.bmiChart ? ((703 * weight) / Math.pow(this.response.height, 2)).toFixed(1) : Math.round(weight);
    },

    setYAxisLabels(data) {
      let min = Math.min(...data);
      let max = Math.max(...data);

      //rOff = ToRoundOffValue
      let rOff = 100 ;

      //To avoid overlapping in the Chart if difference is greater than 99 then
      //we are rounding off the value to  nearest 100
      (max - min) <= 99 ? rOff = 10 : rOff = 100
      max = (Math.ceil(max / rOff) * rOff) + rOff;
      min = (Math.floor(min / rOff) * rOff) - rOff;
      min < 0 ? min = 0 : min;

      this.weightChart.extraOptions.scales.yAxes = [{
        gridLines: {
          tickMarkLength: 20
        },
        ticks: {
          max: max,
          min: min,
        },
        display: true,
      }]
    },

    updateWeightGraph(data, labels) {
      this.setYAxisLabels(data);
      this.weightChart.chartData = {
        datasets: [
          {
            label: this.chartLabel,
            data: data,
            fill: true,
            borderColor: "#2F597A",
            pointBackgroundColor: "#832C44",
            pointBorderColor: "#FFFFFF",
            pointBorderWidth: 3,
            pointStrokeColor: "#fff",
            pointHitRadius: 1,
            pointRadius: 5,
          }
        ],
        labels: labels,
      }
    },
  },
  mounted() {
    this.getWeights();
  }
}
</script>

<style lang="scss">
.weight-bmi-switch {
  .custom-toggle-slider:before {
    background: #2F597A !important;
  }

  .custom-toggle-slider:after {
    color: #5e72e4;
  }
}
</style>

<style scoped>
.active-text {
  letter-spacing: 0.025em;
  font-size: 0.875rem;
}
</style>
