<template>
  <div class="col-md-6 col-sm-12">
    <card v-loading="loader" h-100 type="default" header-classes="bg-transparent" class="equal-height-columns">
      <div slot="header" class="row align-items-center">
        <h1 class="pt-3 pl-3">{{ patient.first_name }} {{ patient.last_name }}</h1>
      </div>
      <h3 class="font-weight-900 pb-2">APP</h3>
      <div class="row pb-3">
        <div class="col-sm-6">
          <span class="history-heading"> Last Used App </span>
        </div>
        <div class="col-sm-6">
          {{ getLastUsed }}
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-sm-6">
          <span class="history-heading"> Last Food Log </span>
        </div>
        <div class="col-sm-6">
          {{ getLastFoodLog }}
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-sm-6">
          <span class="history-heading"> Total Food Logs </span>
        </div>
        <div class="col-sm-6">
          {{ getTotalFoodLogs }}
        </div>
      </div>

      <div class="row pb-3">
        <div class="col-sm-6">
          <span class="history-heading"> Starting Weight </span>
        </div>
        <div class="col-sm-6">
          {{ getStartingWeight }}
        </div>
      </div>
    </card>
  </div>

</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  name: "AppCard.vue",
  props: ['patient', 'foodLogs', 'loader','lastFoodLog','totalFoodLogs'],
  computed: {
    getLastUsed() {
      if (this.patient.last_used === null) {
        return 'N/A';
      }
      return this.$options.filters.globalDateFormat(this.patient.last_used);
    },
    getLastFoodLog() {
      if (!this.lastFoodLog) {
        return 'N/A';
      }
      return this.$options.filters.globalDateFormat(this.lastFoodLog);
    },
    getTotalFoodLogs() {
      return this.totalFoodLogs;
    },
    getStartingWeight() {
      if (this.patient.starting_weight === null) {
        return 'N/A';
      }
      return this.patient.starting_weight
    }
  }
}
</script>

<style scoped>

</style>
